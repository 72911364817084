import request from '@/utils/request';

//统计数
export function statisticsNum() {
  return request({
    url:'/statistics/statisticsNum',
    method : 'post',
  })
}
//统计订单列表
export function statisticsList(data) {
  return request({
    url:'/statistics/statisticsList',
    method : 'post',
    data : data
  })
}

