<template>
  <div class="app-container">
    <div class="overview-layout">
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="out-border">
            <div class="layout-title">合作企业总览</div>
            <div style="padding: 40px">
              <el-row>
                <el-col :span="6" class="color-danger overview-item-value">{{form.todayUserCount}}</el-col>
                <el-col :span="6" class="color-danger overview-item-value">{{form.yesterdayUserCount}}</el-col>
                <el-col :span="6" class="color-danger overview-item-value">{{form.monthUserCount}}</el-col>
                <el-col :span="6" class="color-danger overview-item-value">{{form.totalCompanyNumber}}</el-col>
              </el-row>
              <el-row class="font-medium">
                <el-col :span="6" class="overview-item-title">今日新增</el-col>
                <el-col :span="6" class="overview-item-title">昨日新增</el-col>
                <el-col :span="6" class="overview-item-title">本月新增</el-col>
                <el-col :span="6" class="overview-item-title">合作企业总数</el-col>
              </el-row>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="out-border">
            <div class="layout-title">订单费用总览</div>
            <div style="padding: 40px">
              <el-row>
                <el-col :span="6" class="color-danger overview-item-value">{{form.todayMoney}}</el-col>
                <el-col :span="6" class="color-danger overview-item-value">{{form.yesterdayMoney}}</el-col>
                <el-col :span="6" class="color-danger overview-item-value">{{form.monthMoney}}</el-col>
                <el-col :span="6" class="color-danger overview-item-value">{{form.totalMoney}}</el-col>
              </el-row>
              <el-row class="font-medium">
                <el-col :span="6" class="overview-item-title">今日费用</el-col>
                <el-col :span="6" class="overview-item-title">昨日费用</el-col>
                <el-col :span="6" class="overview-item-title">本月费用</el-col>
                <el-col :span="6" class="overview-item-title">订单总费用</el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="statistics-layout">
      <div class="layout-title">订单统计</div>

      <el-card class="filter-container" shadow="never">
        <div>
          <el-date-picker
              size="small"
              v-model="orderCountDate"
              type="daterange"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="handleDateChange"
          >
          </el-date-picker>
        </div>
      </el-card>

      <div class="table-container">
        <el-table ref="orderTable"
                  :data="tableData"
                  style="width: 100%;"
                  v-loading="listLoading" border>
          <el-table-column label="编号" width="60" align="center">
            <template #default="scope">{{scope.row.id}}</template>
          </el-table-column>
          <el-table-column label="订单编号"  align="center">
            <template #default="scope">{{scope.row.outTradeNo}}</template>
          </el-table-column>
          <el-table-column label="订单金额" align="center">
            <template #default="scope">{{scope.row.expenseMoney}}</template>
          </el-table-column>
          <el-table-column label="支付时间" align="center">
            <template #default="scope">{{scope.row.createTime}}</template>
          </el-table-column>
          <el-table-column label="账号周期" align="center">
            <template #default="scope">{{scope.row.expenseCycle}}{{scope.row.expenseChoice}}</template>
          </el-table-column>
          <el-table-column label="用户" align="center">
            <template #default="scope">{{scope.row.username}}</template>
          </el-table-column>
          <el-table-column label="订单状态" align="center">
            <template #default="scope">{{scope.row.accountStatus == '开通'  ? '首次开通' : '续费' }}</template>
          </el-table-column>


        </el-table>
      </div>

      <!--    分页-->
      <div style="margin: 10px 0">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next,jumper"
            :page-size="listQuery.pageSize"
            :page-sizes="[5,10,15,20,50,100,200,500,1000]"
            :current-page.sync="listQuery.pageNum"
            :total="total">
        </el-pagination>
      </div>
    </div>
  </div>

</template>

<script>


import {statisticsList, statisticsNum} from "@/api/statistics";

const defaultListQuery = {
  pageNum: 1,
  pageSize: 5,
  createTime: null,
  endTime: null,
};

export default {
  name: 'Home',
  data() {
    return {
      listQuery: Object.assign({}, defaultListQuery),
      tableData: [],
      total: null,
      listLoading: false,

      form:{},
      orderCountDate: '',
    }
  },
  created() {
    this.getStatisticsNum();
    //this.initOrderCountDate();
    this.getStatisticsList();
  },
  methods:{
    // 改变当前每页的个数触发
    handleSizeChange(val) {
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = val;
      this.getStatisticsList();
    },
    // 改变当前页码触发
    handleCurrentChange(val) {
      this.listQuery.pageNum = val;
      this.getStatisticsList();
    },



    getStatisticsNum(){
      statisticsNum().then(response=>{
        if (response.code === '0') {
          this.form = response.data;
        }
      })
    },
    initOrderCountDate(){
      //顾头不顾尾,不包括结束时间
      let start = new Date();
      start.setHours(0, 0, 0, 0); // 设置时分秒和毫秒为0
      const end = new Date(start.getTime() + 1000 * 60 * 60 * 24 * 1);
      this.orderCountDate=[start,end];
    },
    handleDateChange(){
      //顾头不顾尾,不包括结束时间
      this.getStatisticsList();
    },
    getStatisticsList(){
      this.listQuery.createTime = this.orderCountDate[0];
      this.listQuery.endTime = this.orderCountDate[1];
      this.listLoading = true;
      statisticsList(this.listQuery).then(res=>{
        if (res.code === '0') {
          this.listLoading = false;
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      })

    },

  }
}
</script>

<style scoped>
.app-container{
  width: 100%;
  overflow: hidden;
}
.total-layout {
  margin-top: 20px;
}

.total-frame {
  border: 1px solid #DCDFE6;
  padding: 20px;
  height: 100px;
}

.total-icon {
  color: #409EFF;
  width: 60px;
  height: 60px;
}

.total-title {
  position: relative;
  font-size: 16px;
  color: #909399;
  left: 70px;
  top: -50px;
}

.total-value {
  position: relative;
  font-size: 18px;
  color: #606266;
  left: 70px;
  top: -40px;
}

.layout-title {
  color: #606266;
  padding: 15px 20px;
  background: #F2F6FC;
  font-weight: bold;
}

.overview-layout {
  margin-top: 20px;
}

.overview-item-value {
  font-size: 24px;
  text-align: center;
}

.overview-item-title {
  margin-top: 10px;
  text-align: center;
}

.out-border {
  border: 1px solid #DCDFE6;
}
</style>
